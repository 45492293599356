html, body {
  overflow-x: hidden;
}
body {
  position: relative
}

.App {
  text-align: center;
}

h1.header {
  font-size: 2rem;
  font-style: normal;
  font-weight: 100;
  line-height: 3rem;
  font-family: 'Ubuntu', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  background-color: rgb(0, 199, 159);
  margin: 0;
  padding: 4rem;
}

.subheader {
  background-color: black;
  color: white;
  margin: 0 0 1rem 0;
  padding: 1rem;
}

.subheader-link:link {
  color: white;
}

.subheader-nav {
  color: rgb(0, 199, 159);
  text-decoration: underline;
}

.subheader-nav:hover {
  cursor: pointer;
}

.subheader-link:visited {
  color: rgb(0, 199, 159);
}

@media (min-width: 1200px) {
  h1.header {
    font-size: 4rem;
  }
}
.book {
  width: 100%;
  float: left;
  padding-bottom: 2em;
}

.book-image {
  width: 30%;
  float: left;
  text-align: center;
  filter: drop-shadow(15px 5px 2px rgba(0, 0, 0, 0.2));
  padding-right: 1em;
}

.book-title {
  line-height: 2rem;
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 0.5em;
}

.book-title a:link {
  color: #111;
}

.book-title a:visited {
  color: #999;
}

.book-title a:hover {
  color: rgb(0, 199, 159);
}

.book-description {
  width: 50%;
  float: left;
  text-align: left;
  color: #111;
  font-family: "Ubuntu", -apple-system, "Segoe UI", "Roboto", "Oxygen", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 300;
  line-height: 1.5rem;
  font-size: 1rem;
}

.highlight {
  background-color: lightblue;
  color:black;
  text-decoration: none;
}

.highlight:hover {
  background-color: yellow;
}

@media only screen and (min-width: 14px)
and (max-width: 736px){ 
  .book {
    padding-top: 2em;
  }

  .book-image {
    width: 100%;
    float: center;
  }

  .book-description {
    width: 90%;
    float: center;
    padding-left: 24px;
  }
}

button {
  margin-bottom: 2em;
  border: 0;
  line-height: 2.5;
  padding: 0 20px;
  font-size: 1rem;
  text-align: center;
  color: #fff;
  text-shadow: 1px 1px 1px #000;
  border-radius: 10px;
  background-color: rgba(0, 170, 159, 1);
  background-image: linear-gradient(to top left,
                                    rgba(0, 0, 0, .2),
                                    rgba(0, 0, 0, .2) 30%,
                                    rgba(0, 0, 0, 0));
  box-shadow: inset 2px 2px 3px rgba(255, 255, 255, .6),
              inset -2px -2px 3px rgba(0, 0, 0, .6);

}
button:hover {
  background-color: rgba(0, 199, 159, 1);
}

button:active {
  box-shadow: inset -2px -2px 3px rgba(255, 255, 255, .6),
              inset 2px 2px 3px rgba(0, 0, 0, .6);
}
